import React from "react";
import icon from "../media/images/google-icon.png";
import { Link } from "react-router-dom";

const LandingTestimonial = () => {
  return (
    <div className="testimonial-component" id="testimonials">
        <div>
        <h2 className="testimonial-component-heading">Customers  <span>Speaks</span> </h2>
         

        <div className="testimonial-component-comments">

            {/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img title="Dog Grooming Prices" src={icon} alt="icon" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
              Fluffy Touch has been providing me with services for the past two
              years, and I’m always satisfied. The groomers at Fluffy Touch are
              the best experts in their work.. &nbsp;
            </div>
            <div className="testimonial-component-comments-col-name">Akash Sharma</div>
            <div className="testimonial-component-comments-col-date">11 months ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img title="Neabot Pet Grooming" src="https://lh3.googleusercontent.com/a-/ALV-UjWvfcFfUBce_BSM1ipKbWzpjVSGezazt9K6RvSx3tYQjMI=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
          {/* ---------- end ------------ */}

          

{/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img title="Grooming Near Me For Dogs" src={icon} alt="Dog Spa" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
            Best pet groomer. Very polite and fabulous at grooming work, my 6 month old Siberian Husky enjoyed his first grooming section a lot with him. Mr. Sonu very..
           
            </div>
            <div className="testimonial-component-comments-col-name">MISU ODIA VLOGS</div>
            <div className="testimonial-component-comments-col-date">a year ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img title="Pet Spa" src="https://lh3.googleusercontent.com/a-/ALV-UjWFHcN13WMpZrlDx8461K7VHeGVgFmRA3X2bIMyK1tMBsc=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
{/* ---------- end ------------ */}


{/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img title="Cheap Pet Grooming Near Me" src={icon} alt="Dog Spa" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
            I took a deluxe grooming service for our two pets (German Shepherd). Full value for money. Wonderfully grooming done, Superb caring by groom.. 
           
            </div>
            <div className="testimonial-component-comments-col-name">Subodh Kumar</div>
            <div className="testimonial-component-comments-col-date">a year ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img title="Dog Haircut" src="https://lh3.googleusercontent.com/a-/ALV-UjXGQKYtmia-GjO_Va2AO-fiAjaAodgvFpsIMNKejWU-SA=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
{/* ---------- end ------------ */}


{/* ---------- start ------------ */}
          {/* <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img title="" src={icon} alt="" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
                My experience was the greatest I've ever had. My pets are so adorable. I'm really happy for my doggu. The entire Fluffy Touch team is incredible. My overall experience with Fluffy Touch has 
              &nbsp;<Link title=""  to="https://maps.app.goo.gl/yfiSDbUxgoJ2FAun6">read more..</Link>
            </div>
            <div className="testimonial-component-comments-col-name">Tushar Chaudhary</div>
            <div className="testimonial-component-comments-col-date">a year ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img title="" src="https://lh3.googleusercontent.com/a-/ALV-UjXEVmknMutyOpGFVZra0F8DVRTrvRcpI0oLniqweOBQyUM=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div> */}
{/* ---------- end ------------ */}


        </div>


        <div className="testimonial-component-header">
        
        <div className="testimonial-component-header-col-1">
            <div className="testimonial-component-header-col-1-heading">
              Customer testimonial on Google
            </div>
            <div className="testimonial-component-header-col-1-text">
              <span>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </span>
              &nbsp; 4.9/5 Rating on Google
            </div>
          </div>
      </div>


      <div className='landing-page-book-button'>
  <a href='#book-now' >Book Call Appointment</a>
</div>

        


        
      </div>
    </div>
  );
};

export default LandingTestimonial;
