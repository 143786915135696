import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import LoadingComponent from "./LoadingComponent";

const LandingHeader = ({location}) => {

  
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // form animation :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [isVisible, setIsVisible] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);  // Text is visible, trigger animation
      } else {
        setIsVisible(false); // Text is not visible, reset animation
      }
    }, { threshold: 0.5 });

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);


// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({

    formType: `${location} Landing Page Booking`,
    name: "",
    phone: "",
    email: "",
    request: "",
    
  })
  
  
 

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      // const response = await fetch("http://localhost:8001/booking-form", {
      const response = await fetch("https://api.shop.fluffytouch.in/booking-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        navigate("/thank-you");

      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
      
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);
  };

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      
      <div className="landing-header" >
    <div>
        <div className="landing-header-main">
          <div className="landing-header-content">
    <div>
          <div className="landing-header-heading">

          <TypeAnimation
                    sequence={[
                      "Pet Grooming At Home",
                      3500,
                      "Pet Designer Hair Cut",
                      3500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
            
          </div>
          <div className="landing-header-heading">in <span><span>{location}</span></span></div>

          </div>
          </div>
          <div className="landing-header-form">

            <form ref={formRef} onSubmit={handleSubmit}
        className={`${isVisible ? 'landing-header-form-animate' : ''}`}>
          <div className="landing-header-form-heading">Our Experts will call you!</div>
                <input required onChange={handleChange} name="name" type="text" placeholder="Name *" />
                <input required onChange={handleChange} name="phone" type="number" placeholder="Phone number *" />
                <input onChange={handleChange} name="email" type="text" placeholder="Email (optional)" />
                <input onChange={handleChange} name="request" type="text" placeholder="Any special request (optional)" />
                <button>Request Callback</button>
            </form>
            
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default LandingHeader;
