import React from "react";
// import logo_icon from "../media/images/ft-red-logo.png";
// import logo_icon from "../media/images/ft-red-logo2.png";
import logo_icon from "../media/images/ft-logo-1.png";
// import logo_icon from "../media/images/ft-white-logo.png";
import { div, Link } from "react-router-dom";

const LandingFooter = () => {
  return (
    <div className="footer-main">
        <div>
        <div className="footer-content">

          <div className="footer-c-col-2">
          <div className="footer-c-col-1">
            
            <div className="footer-logo">
              <div title="Dog Haircut" to="/" className="footer-logo-icon">
                <img title="Dog Spa" src={logo_icon} alt="logo-icon" />
              </div>              
            </div>

            <div className="footer-social">
              <div title="Pet Spa"
                target="_blank"
                to="https://www.facebook.com/Fluffytouchoffc/"
              >
                <i class="fa-brands fa-facebook"></i>
              </div>
              <div title="Dog Grooming At Home"
                target="_blank"
                to="https://www.instagram.com/fluffytouchofficial?igsh=ZGNjOWZkYTE3MQ=="
              >
                <i class="fa-brands fa-instagram"></i>
              </div>
              <div title="Local Dog Groomers"
                target="_blank"
                to="https://twitter.com/i/flow/login?redirect_after_login=%2Ffluffytouchoffc"
              >
                <i class="fa-brands fa-x-twitter"></i>
              </div>
              <div title="Self Service Dog Wash Near Me"
                target="_blank"
                to="https://www.linkedin.com/company/fluffytouch/"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </div>
              <div title="Dog Grooming Services"
                target="_blank"
                to="https://www.youtube.com/channel/UCcUZcYkiAv8t5ruDxYvVn_A"
              >
                <i class="fa-brands fa-youtube"></i>
              </div>
              <div title="Groom Professional" target="_blank" to="https://pin.it/3ZIwZk4">
                <i class="fa-brands fa-pinterest"></i>
              </div>
            </div>
            
          </div>

          {/* <div className="footer-c-col-2"> */}
            <div className="footer-c-c2-section">
              <div className="footer-c-c2-s-heading">QUICK LINKS</div>
              <div className="footer-c-c2-s-points  ">
                
                <a href="#grooming">Grooming</a>
                <a href="#haircut">Haircut</a>
                <a href="#how-we-work">How We Work</a>
                <a href="#why-us">Why Us</a>
                <a href="#testimonials">Testimonials</a>
                <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
                
              </div>
            </div>



            <div className="footer-c-c2-section">
              <div className="footer-c-c2-s-heading">CORPORATE OFFICE</div>
              <div className="footer-c-c2-s-points">
                <div>
                  <span>
                    <i class="fa-solid fa-location-arrow"></i>
                  </span>
                  210, 2nd Floor, Ocean Plaza, <br />
                  Sector-18, Noida - 201301
                </div>
                <Link  target="_blank" to="tel:+919658189189">
                  <span>
                    <i class="fa-solid fa-mobile-screen-button"></i>
                  </span>{" "}
                  +91 96581 89189
                </Link>
                <div>
                  <span>
                    <i class="fa-solid fa-envelope"></i>{" "}
                  </span>
                  info@fluffytouch.in
                </div>
                <Link target="_blank" to="https://wa.me/+919658189189?text=Hello ! I'm Interested in Doorstep Pet Grooming Service. Please contact me.">
                  <span>
                    <i class="fa-brands fa-whatsapp"></i>
                  </span>{" "}
                  +91 9658-189-189
                </Link>
              </div>
            </div>

           


          </div>

          <div className="footer-c-cc">
            Copyright <i class="fa-regular fa-copyright"></i> 2019, FLUFFY
            TOUCH. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default  LandingFooter;
