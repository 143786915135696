import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const ConfirmBooking = () => {
  return (
    <div>
        <Navbar/>
        <div className="confirm-booking">
        <div className="confirm-booking-heading-1">Thanks For Booking</div>
        <div className="confirm-booking-heading-2">Our team will contact you shortly</div>
        {/* <div className="confirm-booking-heading-3">Contact : <Link target='blank' to="tel:+91-9658-189-189">+91 9658-189-189</Link></div> */}
        <br />
        <div className="confirm-booking-link"><Link to="/">go to home page</Link></div>

        </div>
        <Footer/>
    </div>
  )
}

export default ConfirmBooking