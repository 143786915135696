import React from 'react'
import del from "../media/images/FT delhi.png"
import noi from "../media/images/FT Noida.png"
import gtrNoi from "../media/images/FT greater noida.png"
import gur from "../media/images/FT gurgaon.png"
import far from "../media/images/FT faridabad.png"
import ghaz from "../media/images/FT ghaziabad.png"


const LandingOurLocations = () => {
  return (  
    <div>
         <div>
        
        <div className="grooming-service our-location">
      <div className="">
          <h2 className="grooming-service-heading">
            {/* Explore Best <span></span>for Your{" "} */}
            <span>Fluffy Touch </span>At Home Serviced <span>Area</span>
          </h2>

          <div className="grooming-service-content">

            <div title="Dog Spa Near Me" to="/pet-grooming-at-home/delhi" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="Mobile Cat Grooming" src={del} alt="service" />
              </div>
              <div className="location-service-content-col-text">
              Pet Grooming <br/>in <span>Delhi</span>
              </div>
            </div>

            <div title="Dog Groomers That Come To Your House" to="/pet-grooming-at-home/noida" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="Mobile Dog Wash Near Me" src={noi} alt="service" />
              </div>
              <div className="location-service-content-col-text">
              Pet Grooming <br/>in <span>Noida</span>
              </div>
            </div>

            <div title=" Dog Grooming Bath" to="/pet-grooming-at-home/greater-noida" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="Dog Haircut Near Me" src={gtrNoi} alt="service" />
              </div>
              <div className="location-service-content-col-text location-service-content-col-text-1">
              Pet Grooming <br/>in <span>Greater Noida</span>
              </div>
            </div>

            <div title="Dog Spa" to="/pet-grooming-at-home/gurugram" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="Pet Wash Near Me" src={gur} alt="service" />
              </div>
              <div className="location-service-content-col-text">
              Pet Grooming <br/>in <span>Gurugram</span>
              </div>
            </div>

            <div title="Dog Nail Clipping Near Me" to="/pet-grooming-at-home/ghaziabad" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="Pet Grooming Home Service" src={ghaz} alt="service" />
              </div>
              <div className="location-service-content-col-text">
              Pet Grooming <br/>in <span>Ghaziabad</span>
              </div>
            </div>

            <div title="Mobile Dog Wash" to="/pet-grooming-at-home/faridabad" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="Dog Salon Near Me" src={far} alt="service" />
              </div>
              <div className="location-service-content-col-text">
              Pet Grooming <br/>in <span>Faridabad</span>
              </div>
            </div>

            {/* <div title="" to="/" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="" src={ben} alt="service" />
              </div>
              <div className="location-service-content-col-text">
              Pet Grooming <br/>in <span>Bengaluru</span>
              </div>
            </div>
            

            <div title="" to="/" className="grooming-service-content-col">
              <div className="our-location-content-col-img">
                <img title="" src={chan} alt="service" />
              </div>
              <div className="location-service-content-col-text location-service-content-col-text-1">
              Pet Grooming <br/>in <span>Chandigarh</span>
              </div>
            </div> */}

          </div>
        </div>

        <div className='landing-page-book-button'>
  <a href='#book-now' >Book Call Appointment</a>
</div>
      </div>
    </div>
    </div>
  )
}

export default LandingOurLocations