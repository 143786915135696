import React, { useState } from 'react'

const LandingGroomingComponent = ({location}) => {

     // grooming package start --------------------------------------------------------------------------------
        
        const [petGroom, setPetGroom] = useState("Dog")
        const [petTraining, setPetTraining] = useState("Dog")
    
        const [readmore, setReadmore] = useState(false)
        
    
        const [openForm, setOpenForm] = useState(false)
        const [dynamicForm, setDynamicForm] = useState(false)
        const [pet, setPet] = useState("")
        const [service, setService] = useState("")
        
        // grooming package end --------------------

            const [boardingForm, setBoardingForm] = useState(false)
        
    
  return (
    <div>

<div className="package">
            <div>

                <div className="package-head" id='grooming'>
                   
                   <div className="package-head-heading"><span>Grooming</span> Price in <span className='location-page-location'>{location}</span></div>
                   
                    <div className="package-head-text">We Offer The Best Grooming Services For Your Pets.</div>
                   
                </div>

                <div className="package-content">
                    
                    <div className="package-content-head-2">

                        <div 
                        onClick={e=>{setPetGroom("Dog")}}
                        className={
                            petGroom === "Dog"? "package-content-head-red":""
                        }>Dog</div>
                        
                        <div 
                        onClick={e=>{setPetGroom("Cat")}}
                        className={
                             petGroom === "Cat"? "package-content-head-red":""
                        }>Cat</div>
                       
                    </div>

{petGroom === "Dog"?
                     <div className="package-content-list">
                     <div className="package-content-list-heading"><span>Dog Grooming</span> Packages in <span className='location-page-location'>{location}</span></div>
                     
                     <div className="package-content-list-columns">

                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                 <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                 <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Face Haircut</div>
                                 <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                 <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                 
                                 <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 
                                 <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Minor Hair Cut</div>
                                 
                                 <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                 <div className="package-content-list-col-point">Full Body Haircut</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Mouth Spray</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Spritz of Perfume</div>
                                 
                                 <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                 <div className="package-content-list-col-point">Designer Haircut</div>
                                 <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nails Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Spritz of Perfume</div>
                                 <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                     {
                                         readmore?"Read Less"
                                         :"Read More"
                                     }
                                 </div>
{
 readmore?
<>
                                 <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                 <div className="package-content-list-col-point">Full Body Massage</div>
                                 <div className="package-content-list-col-point">Mouth Freshener Spray</div>
</>
:""}
                                 <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                 
                             </div>
                         </div>

                     </div>

                 </div>

:petGroom === "Cat"?
                   
                   
<div className="package-content-list">
                        <div className="package-content-list-heading"><span>Cat Grooming</span> Packages in <span className='location-page-location'>{location}</span></div>
                        
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Ear Cleaning</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Minor Hair Cut</div>
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                    <div className="package-content-list-col-point">Full Body Haircut</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Mouth Spray</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>

                                    <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                        {
                                            readmore?"Read Less"
                                            :"Read More"
                                        }
                                    </div>
                                  
                                    {
    readmore?
<>
                                    <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                    <div className="package-content-list-col-point">Full Body Massage</div>
                                    <div className="package-content-list-col-point">Mouth Freshener Spray</div>
                                    </>
:""}
     
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                </div>
                            </div>

                        </div>

                    </div>


:""
                    
                    }



                </div>

                {/* ------------------------------------------------------------------- */}
                <div className="package-training-margin-top"></div>
                <div className="package-head" id='haircut'>
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    <div className="package-head-heading"><span>Pet</span> Designer<span> Haircut</span> Price in <span className='location-page-location'>{location}</span></div>
                    {/* <div className="package-head-text">We Offer The Best Grooming And Boarding Services For Your Pets.</div> */}
                    <div className="package-head-text">We Offer The Best Grooming Services For Your Pets.</div>
                </div>


                <div className="package-content">
                    <div className="package-content-head-2">
                        <div 
                        onClick={e=>{setPetTraining("Dog")}}
                        className={
                            petTraining === "Dog"? "package-content-head-2-red":""
                        }>Dog Designer Hair Cutting</div>
                        <div 
                        onClick={e=>{setPetTraining("Cat")}}
                        className={
                            petTraining === "Cat"? "package-content-head-2-red":""
                        }>Cat Designer Hair Cutting</div>
                        {/* <div 
                        onClick={e=>{setPetTraining("Pet")}}
                        className={
                            petTraining === "Pet"? "package-content-head-2-red":""
                        }>Pets Training Packages </div> */}

                    </div>


                    <div className="package-content-list">
                        <div className="package-content-list-heading"><span>{petTraining}</span> Designer <span>Hair Cutting</span> Packages in <span className='location-page-location'>{location}</span> </div>
                        <div className="package-content-list-columns">

                            <div className={`package-content-list-col-img-${petTraining}`}>

                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">{petTraining} HAIR TRIMMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Hair Trim</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>

                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">{petTraining} DESIGNER HAIR CUTTING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1799 </span> Rs. 1399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <a href='#book-now' className="package-content-list-col-button">Book Call Appointment</a>
                                    
                                    </div>
                            </div>


                        </div>
                    </div>


                </div>

            </div>
        </div>
        
        
    </div>
  )
}

export default LandingGroomingComponent