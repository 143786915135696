import React from 'react'
import Navbar from '../components/Navbar'

import { Helmet } from "react-helmet";
import LandingFooter from '../components/LandingFooter'
import PrivacyPolicyComponent from '../components/PrivacyPolicyComponent'

const PrivacyPolicyPage = () => {
  return (
    <div>

{/* <Helmet>
        <title>Fluffy Touch - Gallery : Pet Grooming and Boarding Images </title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful as they groom your pets in the comfort of your home.
        "
        />
      </Helmet> */}
      
      <Navbar />
        
<PrivacyPolicyComponent/>
        <LandingFooter/>
        
    </div>
  )
}

export default PrivacyPolicyPage